import GlobalLayout from "components/page/GlobalLayout";
import SensorSpecsPage from "components/sensors/SensorSpecsPage";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function E1() {
  const renderContent = data => {
    const sensor = {
      title: "E15",
      imageHeader: data.e15Header,
      imageHeaderMobile: data.e15HeaderMobile,
      image: data.e15Dims,
      objectPosition: "center",
      description:
        "Proactively detect changes in temperature and humidity. Immediately address potentially harmful fluctuations and eliminate the risk of hazardous conditions. ",
      pdf: "/files/specs/E15.pdf",
      column1: [
        {
          title: "Dimensions",
          text: "70mm (2.76in) x 32.2mm (1.27in) x 18.2mm (0.72in)",
        },
        {
          title: "Range",
          text: "Up to 100m (328ft)",
        },
        {
          title: "Battery Type",
          text: "Replaceable 2x1.5V AAA (Lithium Batteries included)",
        },
        {
          title: "Battery Life",
          text: "Up to 5 years with typical medium-heavy use",
        },
        {
          title: "Weight",
          text: "32.4g (w/ battery)",
        },
      ],
      column2: [
        {
          title: "Temperature Measurement",
          text: "±0.3°C (0°C to 60°C), ±0.5°C (-20°C to 0°C)",
        },
        {
          title: "Humidity Measurement",
          text:
            "±4.5% (0% to 10% RH), ±3% (10% to 90% RH), ±3.8% (90% to 95% RH) ",
        },
        {
          title: "Mounting",
          text: "Two-sided tape (included)",
        },
        {
          title: "Weather Resistance",
          text: "IPX4",
        },
        {
          title: "NDAA, FCC, CE, REACH, RoHS, IC, and RCM Compliant",
        },
        {
          title: "2-Year Warranty Included",
        },
      ],
    };

    return (
      <GlobalLayout dark color="transparent">
        <Helmet>
          <title>
            Rhombus E15 Environmental Sensor - Temperature & Humidity
          </title>
          <meta
            name="description"
            content="Proactively detect changes in temperature and humidity. Immediately detect potentially harmful fluctuations and address issues."
          />
          <meta
            name="thumbnail"
            content="https://rhombus.com/img/e15-seo.png"
          />
        </Helmet>
        <SensorSpecsPage sensor={sensor} />
      </GlobalLayout>
    );
  };

  const GET_IMAGES = graphql`
    query {
      e15Header: file(
        relativePath: { eq: "components/sensors/img/e15-header-2000-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      e15HeaderMobile: file(
        relativePath: {
          eq: "components/sensors/img/e15-header-mobile-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      e15Dims: file(
        relativePath: { eq: "components/sensors/img/e15-dims-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
